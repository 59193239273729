.ProjectsGrid {
  flex-basis: 100%;

  &__ToggleButton {
    margin-bottom: 30px;
    border-radius: 4px !important;
    button {
      border-right: 1px solid #eee;
    }
    button:last-child {
      border-right: none;
    }
  }

  .grid {
    /* item is invisible, but used for layout */
    .grid-item,
    .grid-sizer {
      width: 100%;
      @media (min-width: 576px) {
        width: calc(50% - 12px);
      }
      @media (min-width: 768px) {
        width: calc(33.33% - 16px);
      }
      @media (min-width: 992px) {
        width: calc(25% - 18px);
      }
    }

    .gutter-sizer {
      width: 24px;
    }

    .grid-item {
      float: left;
      margin-bottom: 24px;
      transition: width 0.3s, height 0.3s;
      /* item-content is visible, and transitions size */
      .grid-item-content {
        width: 100%;
        height: 100%;
      }
      /* both item and item content change size */
      &.is-expanded {
        width: 100%;
        @media (min-width: 768px) {
          width: calc(66.66% - 8px);
        }
        @media (min-width: 992px) {
          width: calc(50% - 12px);
        }
      }
    }
  }

}
